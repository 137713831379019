import request from '@/utils/request'


// 查询直播列表
export function listLive(query) {
  return request({
    url: '/live/live/list',
    method: 'get',
    params: query
  })
}

// 查询直播分页
export function pageLive(query) {
  return request({
    url: '/live/live/page',
    method: 'get',
    params: query
  })
}

// 查询直播详细
export function getLive(data) {
  return request({
    url: '/live/live/detail',
    method: 'get',
    params: data
  })
}

// 新增直播
export function addLive(data) {
  return request({
    url: '/live/live/add',
    method: 'post',
    data: data
  })
}

// 修改直播
export function updateLive(data) {
  return request({
    url: '/live/live/edit',
    method: 'post',
    data: data
  })
}

// 删除直播
export function delLive(data) {
  return request({
    url: '/live/live/delete',
    method: 'post',
    data: data
  })
}
